import { Formik } from 'formik'
import { Redirect, withRouter } from 'react-router-dom'
import {
  __,
  equals,
  find,
  gt,
  path,
  pathEq,
  pathOr,
  pipe,
  prop,
  where,
  whereEq
} from 'ramda'
import {
  branch,
  compose,
  renderComponent,
  setDisplayName,
  withHandlers,
  withProps,
  withState,
  setStatic
} from 'recompose'
import { connect } from 'react-redux'
import { withApollo } from 'react-apollo'
import jwtDecode from 'jwt-decode'

import Login from '../../components/login/login'

import {
  FORM_MESSAGES,
  FORM_VALUES,
  SUBSCRIPTION_STATUS
} from '../../constants'
import { STORAGE_KEY_EMAIL_ON_GUEST_HOME_PAGE } from '../../storage-keys'
import { checkIsAuthenticated } from '../../lib/auth'
import {
  findConfigValue,
  findPremiumIntroductionVersion
} from '../../lib/config'
import { pushQueryParams } from '../../lib/location'
import { getGQLErrorMsg } from '../../lib/apollo'
import { getSearchProperty } from '../../lib/query-string'
import { isNotNil } from '../../lib/fp'
import { loginGa } from '../../lib/analytics/ga'
import Yup from '../../lib/yup'
import {
  enableLoadingOverlay,
  endLoadingOverlay,
  login,
  saveHasSeenRenewalPrompt,
  saveHasSeenRepairPrompt,
  saveIntroductionVersion,
  switchProfile
} from '../../actions'
import { selectPremiumIntroductionModal } from '../../lib/modal'
import withConfigCacheOnly from '../../hoc/with-config'

import ACCOUNT_QUERY from '../../../graphql/queries/account.gql'
import LOGIN_QUERY from '../../../graphql/queries/login.gql'

import { customDimensions } from '../../lib/analytics/custom-dimensions'
import withDeviceFingerprint from '../../hoc/with-device-fingerprint'
import { segmentIdentify } from '../../segment/segment-identify'
import { segmentTrackSignin } from '../../segment/segment-track'
import { getOperatingSystem, getDevice } from '../../utils/os-device'

const mapStateToProps = state => {
  return {
    isAuthenticated: checkIsAuthenticated(state),
    accounts: state.accounts
  }
}

const enhance = compose(
  setDisplayName('LoginContainer'),
  withRouter,
  withApollo,
  withConfigCacheOnly,
  withState('rememberMe', 'setRememberMe', true),
  withHandlers({
    onRememberMeChange: ({ setRememberMe }) => (event, value, checked) => {
      setRememberMe(checked)
    }
  }),
  withDeviceFingerprint,
  withProps(({ appConfig }) => ({
    email: getSearchProperty('email'),
    loginBackgroundUri: findConfigValue('URL_LOGIN_BACKGROUND')(appConfig)
  })),
  connect(
    mapStateToProps,
    dispatch => ({
      dispatch
    })
  ),
  branch(
    ({ isAuthenticated }) => isAuthenticated,
    compose(
      withProps(({ location }) => ({
        to: prop('pathname', location)
      })),
      renderComponent(Redirect)
    )
  ),
  withState('firstElement', 'setFirstElement'),
  setStatic('getDerivedStateFromProps', ({ firstElement }, state) => {
    if (pathOr(false, ['touched', 'email'], state)) {
      return
    }

    if (firstElement) {
      setTimeout(() => firstElement.focus(), 100)
    }
  }),
  Formik({
    // Define our form's validation schema with Yup. It's like Joi, but for
    // the browser.
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email(FORM_MESSAGES.email.valid)
        .required(FORM_MESSAGES.required),
      password: Yup.string()
        .required(FORM_MESSAGES.required)
        .min(FORM_VALUES.password.min, FORM_MESSAGES.password.min)
    }),

    // Map the field values to props if necessary. Used as empty strings for empty forms
    mapPropsToValues: ({ email }) => {
      return {
        email: email || sessionStorage.getItem(STORAGE_KEY_EMAIL_ON_GUEST_HOME_PAGE) || '',
        password: ''
      }
    },

    // Formik lets you colocate your submission handler with your form.
    // In addition to the payload (the result of mapValuesToPayload), you have
    // access to all props and some stateful helpers.
    handleSubmit: (values, { props, setError, setSubmitting }) => {
      // e.preventDefault(), setSubmitting, setError(undefined) are
      // called before handleSubmit is. So you don't have to do repeat this.
      // handleSubmit will only be executed if form values pass Yup validation.
      setError('')
      sessionStorage.removeItem(STORAGE_KEY_EMAIL_ON_GUEST_HOME_PAGE)
      const { accounts } = props
      const key = new Date().getTime()
      props.dispatch(enableLoadingOverlay(key, 'login'))
      props.client
        .query({
          query: LOGIN_QUERY,
          variables: {
            email: values.email,
            password: values.password
          }
        })
        .then(result => {
          // Since we get the full account here,
          // might as well put it in the store for later!
          props.client.writeQuery({
            query: ACCOUNT_QUERY,
            data: {
              account: path(['data', 'login'], result)
            }
          })
          return result
        })
        .then(result => {
          setSubmitting(false)

          const loginData = pathOr({}, ['data', 'login'], result)
          const subscription = path(['data', 'login', 'subscription'], result)
          const session = path(['data', 'login', 'session'], result)
          const jwtDecoded = jwtDecode(prop('token', session))
          const accountId = prop('accountId', jwtDecoded)
          const sessionId = prop('sessionId', jwtDecoded)
          const profiles = path(['data', 'login', 'profiles'], result)
          const selectedProfile = path(
            ['data', 'login', 'selectedProfile'],
            result
          )
          const prevSelectedProfileId = path(
            [accountId, 'selectedProfile'],
            accounts
          )
          const seenIntroductionVersion = pathOr(
            0,
            [accountId, 'lastSeenPremiumIntroductionVersion'],
            accounts
          )
          const hasSeenRepairPrompt = pathEq(
            [accountId, 'hasSeenRepairPrompt'],
            true,
            accounts
          )
          const hasSeenRenewalPrompt = pathEq(
            [accountId, 'hasSeenRenewalPrompt'],
            true,
            accounts
          )
          const subscriptionIsCancelled = pathEq(
            ['status'],
            SUBSCRIPTION_STATUS.CANCELLED,
            subscription
          )
          const subscriptionIsSuspended = pathEq(
            ['renewalStatus'],
            SUBSCRIPTION_STATUS.SUSPENDED,
            subscription
          )

          const premiumIntroduction = findPremiumIntroductionVersion(
            props.appConfig
          )

          const shouldSeeNewIntro = where({
            version: gt(__, seenIntroductionVersion),
            enabled: equals(true)
          })(premiumIntroduction)

          const shouldSeeRenewalPrompt =
            subscriptionIsCancelled && !hasSeenRenewalPrompt

          const shouldSeeRepairPrompt =
            subscriptionIsSuspended && !hasSeenRepairPrompt

          const shouldSeeWelcomeBack = subscription.status === SUBSCRIPTION_STATUS.NONE

          const isDefaultProfile = pipe(
            find(whereEq({ id: selectedProfile, isDefault: true })),
            isNotNil
          )(profiles)

          const {
            dispatch, history, location, rememberMe
          } = props
          dispatch(login(loginData, rememberMe))

          loginGa({
            action: 'Manual login',
            label: accountId,
            [customDimensions.SessionId]: sessionId,
            [customDimensions.UserId]: accountId
          })

          // Add segment data identify for sign in
          const currentProfile = profiles.find(item => item.id === selectedProfile)
          segmentIdentify(loginData, currentProfile)

          // Add segment data analytics for sign in
          const { name: osName } = getOperatingSystem()
          const { model } = getDevice()
          // Web app version GITHUB_COMMIT_HASH will be replaced during build
          segmentTrackSignin({
            email: loginData.email,
            os: osName,
            platform: jwtDecoded.platform,
            model,
            deviceId: jwtDecoded.deviceId,
            appVersion: 'a6b875be169aa5714bfbeefb4780376453328efb',
            browser: jwtDecoded.browser
          })

          // Since we're mixin sync code with async code
          return Promise.resolve()
            .then(() => {
              // Handle welcome dialog

              // Don't display welcome dialog if not default profile
              if (!isDefaultProfile) {
                return null
              }

              if (
                shouldSeeRenewalPrompt ||
                shouldSeeNewIntro ||
                shouldSeeRepairPrompt ||
                shouldSeeWelcomeBack
              ) {
                if (shouldSeeRepairPrompt) {
                  dispatch(saveHasSeenRepairPrompt(accountId))
                } else if (shouldSeeRenewalPrompt) {
                  dispatch(saveHasSeenRenewalPrompt(accountId))
                } else if (shouldSeeNewIntro) {
                  dispatch(
                    saveIntroductionVersion(
                      accountId,
                      premiumIntroduction.version
                    )
                  )
                }

                const premiumIntroModalParams = selectPremiumIntroductionModal(
                  subscription
                )
                return pushQueryParams(
                  history,
                  location,
                  premiumIntroModalParams
                )
              }
              return null
            })
            .then(() => {
              // if there's a previous login
              // and last selected profile is different from the default
              if (
                prevSelectedProfileId &&
                prevSelectedProfileId !== selectedProfile
              ) {
                return props.dispatch(
                  switchProfile({ id: prevSelectedProfileId })
                )
              }

              return Promise.resolve()
            })
        })
        .then(() => {
          // temporary fix for redirect after login in sign up page
          const { history, location } = props
          if (location.pathname.includes('signup')) {
            history.push('/')
          }
          props.dispatch(endLoadingOverlay(key, 'login', { timeout: 2000 }))
        })
        .catch(error => {
          setSubmitting(false)
          setError(getGQLErrorMsg(error))
          props.dispatch(endLoadingOverlay(key, 'login', { error }))
        })
    }
  })
)

export default enhance(Login)
